$bannerHeight: 60px;
$animationDuration: 200ms;
:export {
  animationDuration: $animationDuration;
  bannerHeight: $bannerHeight;
}

@keyframes slide-down {
  from {
    transform: translateY(-$bannerHeight);
  }
  to {
    transform: translateY(0);
  }
}

.banner {
  position: fixed;
  top: 0;
  width: 100%;
  height: $bannerHeight;
  z-index: 9999;
  display: flex;
  font-family: Helvetica, Arial, sans-serif;
  align-items: center;
  background-color: #f1f1f1;
  box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.15);
  will-change: transform;
  animation: slide-down $animationDuration linear;
}

.site-container-push {
  position: fixed !important;
  top: 0;
  transform: translateY($bannerHeight);
  > div {
    // only a fallback, as 100vh does not take address bar into account.
    // so this will be overridden by JS
    height: calc(100vh - #{$bannerHeight});
    overflow-y: auto;
  }
}

.site-container-push-animation {
  transition: transform $animationDuration linear;
}

.banner-remove {
  transition: top $animationDuration linear;
  top: -$bannerHeight;
}

.text {
  flex: 1;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  color: #000;
}

.close-button {
  padding: 14px;
  cursor: pointer;
  background: transparent;
  border: 0;
  svg {
    width: 13px;
    height: 13px;
  }
}

.app-link {
  display: block;
  margin-right: 14px;
  font-size: 13px;
  color: #000;
  width: 89px;
  height: 28px;
  line-height: 28px;
  border: 2px solid #000;
  border-radius: 16px;
  text-decoration: none;
  background: #fdfdfd;
  text-align: center;
  font-weight: bolder;
}
